import { EnvironmentInterface } from '../app/_shared/interfaces/environment.interface';

export const environment: EnvironmentInterface = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBm4pWNzbG_8oGovVPbKK6qIm0G6LJQ3L4",
    authDomain: "blush-prod-425512.firebaseapp.com",
    databaseURL: "https://blush-prod-425512-default-rtdb.firebaseio.com",
    projectId: "blush-prod-425512",
    storageBucket: "blush-prod-425512.appspot.com",
    messagingSenderId: "188385306750",
    appId: "1:188385306750:web:f5fcaf68ba03ef5098502f",
    measurementId: "G-0MYWR921RR",
  },
  apiUrl: 'https://us-central1-blush-prod-425512.cloudfunctions.net',
  apiPosUrl: 'https://us-central1-blush-prod-425512.cloudfunctions.net',
  mapsApiKey: 'AIzaSyBdr7hxokuupUflBsCW50gzgJjPy4uQadU',
  baseUrl: 'https://blush-cms.web.app',
  release: '0.0.02',
  envName: 'PROD',
  hoodieAPIkey: '64b0137687c0842a46a7a150ddafb443',
  searchAPIkey: 'd673dc9651f37064602675b471404a43',
  makioUrl: 'https://makio-prod.leafvip.app',
};
